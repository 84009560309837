import './registerServiceWorker'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import { loadFonts } from './plugins/webfontloader'
loadFonts()

import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: 'http://localhost:8080/',
  headers: {}
})


const app = createApp(App)
  .use(router)
  .use(vuetify);

// const api = "http://localhost/hakuna/hakuna-api/";
const api = "https://proyectos.nebulajuno.com/hakuna/api/app/";

app.config.globalProperties.$api = api;
app.config.globalProperties.version = '22.1209.0900';
app.config.globalProperties.$axios = { ...axiosInstance }

app.mount('#app')
