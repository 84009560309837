<template>
  <v-app
    class="menu-layer"
    style="background-color: #ff570f;"
  >
    <br>
    <img
      :src="require('@/assets/logo/hakuna-negro.svg')"
      style="max-width: 350px;"
    />
    <br>
    <div class="display-6">Disculpa los inconvenientes, <br>nos estamos renovando</div>
    <!-- <HeaderMenu />
    <v-main>
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>
    <FooterMenu /> -->
  </v-app>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";
export default {
  name: "App",
  setup() {
    const api = "hola2";
  },
  components: { HeaderMenu, FooterMenu },
  data() {
    return {
      name: "",
    };
  },
};
</script>
<style lang="scss">
@import "@/styles/main";
</style>