import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Inicio',
      component: HomeView
    }, {
      path: '/menu',
      name: 'Menu',
      component: () => import('@/views/MenuView.vue')
    }, {
      path: '/sucursales',
      name: 'Sucursales',
      component: () => import('@/views/SucursalesView.vue')
    }, {
      path: '/preguntas-frecuentes/:tab?',
      name: 'Preguntas',
      component: () => import('@/views/PreguntasView.vue')
    }, {
      path: '/:pathMatch(.*)*', name: 'NotFound',
      component: () => import('@/views/404View.vue')
    }
  ]
},)

export default router
