<template>
  <footer
    class="gap no-bottom"
    style="background-color: #232323;"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-12">
          <div class="footer-description">
            <a href="index.html">
              <img
                class="img-fluid"
                style="max-width:300px;padding: 24px!important;"
                :src="logo"
                alt="Logo Hakuna Bolas de Arroz"
              />
            </a>
            <h2>!Todo es mejor en bola!</h2>
            <!-- <p>Vitae congue mauris rhoncus aenean. Enim nulla aliquet porttitor lacus luctus accumsan tortor posuere. Tempus egestas sed sed risus pretium quam.</p> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="menu">
            <h4>Menu</h4>
            <ul class="footer-menu">
              <li
                v-for="(link,idx) in links"
                :key="idx"
              >
                <v-btn
                  color="white"
                  :to="link.to"
                  variant="text"
                >
                  <v-icon
                    v-if="link.icon"
                    :icon="link.icon"
                  />&nbsp;
                  {{link.text}}
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="menu contacts">
            <h4>Contacto</h4>
            <div class="footer-location">
              <router-link
                to="//g.page/HakunaBolasdeArroz?share"
                target="_blank"
              >
                <v-icon
                  color="white"
                  size="x-large"
                >place</v-icon>&nbsp;
                Av. Francisco Zarco 3204, Zarco, 31020 Chihuahua, Chih.
              </router-link>
            </div>
            <a href="mailto:webmasterhakuna@gmail.com"><i class="fa-solid fa-envelope"></i>webmasterhakuna@gmail.com</a>
            <a href="callto:+526142279280"><i class="fa-solid fa-phone"></i>+52 614 227 92 80</a>
          </div>
          <ul class="social-media">
            <li
              v-for="(social,idxs) in sociales"
              :key="idxs"
            >
              <a
                :href="social.to"
                target="_blank"
              >
                <i :class="social.icon"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-two gap no-bottom">
        <p>Copyright © 2022. Restaurantes Hakuna. Todos los derechos.</p>
        <div class="privacy">
          <a href="#">Política de privacidad</a>
          <a href="#">Términos y condiciones</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import logo from "@/assets/logo/hakuna-naranja.svg";
export default {
  name: "FooterMenu",
  data() {
    return {
      logo,
      links: [
        {
          to: "/",
          text: "Inicio",
          name: "Inicio",
          icon: "home",
        },
        {
          to: "/sucursales",
          text: "Sucursales",
          name: "Sucursales",
          icon: "place",
        },
        {
          to: "/menu",
          text: "Menú",
          name: "Menu",
          icon: "menu_book",
        },
        {
          to: "/preguntas-frecuentes/facturacion",
          text: "Facturación",
          name: "Preguntas",
          icon: "description",
        },
        {
          to: "/preguntas-frecuentes",
          text: "Ayuda",
          name: "Preguntas",
          icon: "question_mark",
        },
      ],
      sociales: [
        {
          to: "//facebook.com/HakunaBolasdeArroz",
          text: "Facebook",
          icon: "fa-brands fa-facebook",
        },
        {
          to: "//instagram.com/hakunabolasdearroz/",
          text: "Instagram",
          icon: "fa-brands fa-instagram",
        },
        {
          to: "//twitter.com/bolashakuna",
          text: "Twitter",
          icon: "fa-brands fa-twitter",
        },
        {
          to: "//tiktok.com/@hakunabolas",
          text: "Tiktok",
          icon: "fa-brands fa-tiktok",
        },
      ],
    };
  },
};
</script>