// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { aliases, md } from 'vuetify/iconsets/md';

// Vuetify
import { createVuetify } from 'vuetify'
import { md3 } from 'vuetify/blueprints';

export default createVuetify({
  blueprint: md3,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: { md, },
  },
  theme: {
    defaultTheme: 'defaultTheme',
    themes: {
      defaultTheme: {
        colors: {
          background: '#fff',
          surface: '#fff',
          primary: '#232323',
          'primary-darken-1': '#000',
          secondary: '#FF570F',
          'secondary-darken-1': '#B83700',
          error: '#E20338',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      },

    }
  },
})
