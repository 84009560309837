<template>
  <!-- <HelloWorld /> -->
  <section
    class="hero-section gap"
    :style="{ 'background-image': 'url(' + bgImage + ')' }"
  >
    <div class="container">
      <br><br>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="restaurant">
            <h1 style="text-transform: none!important;">!Todo es mejor en bola!</h1>
            <p>Somos una empresa consolidada como la primera opción en la ciudad de Chihuahua en la venta de las mejores bolas de arroz.</p>
            <br>
            <v-btn
              size="x-large"
              variant="flat"
            >
              <v-icon>shopping_bag</v-icon>¡Ordena ahora!
            </v-btn>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img-restaurant">
            <img
              alt="man"
              :src="foto1"
              class="img-fluid"
              style="border-radius: 24px"
            >
            <div class="bolaMenu">
              <img
                alt="img"
                :src="menuItem"
              >
              <div>
                <p>Bola de arroz del mes</p>
                <h6>Bola de camarón</h6>
                <div>
                  <v-icon
                    icon="star"
                    color="secondary"
                  />
                  <v-icon
                    icon="star"
                    color="secondary"
                  />
                  <v-icon
                    icon="star"
                    color="secondary"
                  />
                  <v-icon
                    icon="star"
                    color="secondary"
                  />
                  <v-icon
                    icon="star_half"
                    color="secondary"
                  />
                </div>
              </div>
            </div>
            <div class="bolaMenu location-restaurant">
              <i class="fa-solid fa-location-dot"></i>
              <div>
                <h6>5 Restaurantes</h6>
                <p>En Chihuahua!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br><br><br>
  </section>
  <!-- works-section -->
  <section class="works-section gap no-top">
    <div class="container">
      <div class="hading">
        <h2 style="text-transform: none!important;">Ordena y pasa a recoger</h2>
        <p>Ahora pide tu Hakuna favorita desde nuestra aplicación, es muy sencillo solo tienes que seguir los siguientes pasos:</p>
      </div>
      <div class="row ">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="work-card">
            <div style="padding:48px;">
              <img
                alt="img"
                class="img-fluid"
                style="border-radius:24px"
                :src="manual01"
              >
            </div>
            <h4><span>1</span><br>Selecciona tu bola</h4>
            <p>Selecciona tu bola de arroz o boneless favoritos y personalízalo a tu gusto.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="work-card">
            <div style="padding:48px;">
              <img
                alt="img"
                class="img-fluid"
                style="border-radius:24px"
                :src="manual02"
              >
            </div>
            <h4><span>2</span><br>Selecciona tu sucursal</h4>
            <p>Selecciona la sucursal más cercana a ti.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">

          <div class="work-card">
            <div style="padding:48px;">
              <img
                alt="img"
                class="img-fluid"
                style="border-radius:24px"
                :src="manual03"
              >
            </div>
            <h4><span>3</span><br> Pasa a recoger</h4>
            <p>Tu pedido estará listo para recoger en la sucursal que hayas seleccionado.</p>
          </div>
        </div>
      </div>
      <div style="text-align: center!important;">
        <br><br>
        <v-btn
          size="x-large"
          color="secondary"
        >¡Ordena aquí!</v-btn>
      </div>
    </div>
  </section>
  <!-- best-restaurants -->

  <!-- your-favorite-food -->
  <section
    class="your-favorite-food gap"
    :style="{ 'background-image': 'url(' + bgImage + ')' }"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="food-photo-section">
            <img
              alt="img"
              class="img-fluid"
              style="border-radius:24px"
              :src="foto2"
            >
            <!-- <a
              href="#"
              class="one"
            >
            Bolas de arroz
            </a>
            <a
              href="#"
              class="two"
            >Alitas</a>
            <a
              href="#"
              class="three"
            >Boneless</a> -->
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="food-content-section">
            <h2>Hakuna se disfruta en familia</h2>
            <p>Disfruta de tus bolas de arroz y boneless favoritos con tus seres queridos, ahora al alcance de un clic.</p>
            <v-btn size="x-large">
              <v-icon>shopping_bag</v-icon> Ordena ahora
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- counters-section -->
  <section class="counters-section">
    <br><br><br>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <h2>¡Conócenos!</h2>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="count-time">
            <h2>+976</h2>
            <p>Bolas de arroz por día</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="count-time">
            <h2>+100</h2>
            <p>Colaboradores</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="count-time sp">
            <h2>40<span style="font-size:30px">k+</span></h2>
            <p>Followes en social media
            </p>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import bgImage from "@/assets/img/background-1.png";
import foto1 from "@/assets/fotos/foto1.jpg";
import foto2 from "@/assets/fotos/foto2.jpg";
import menuItem from "@/assets/menu/bola_camaron.png";
import manual01 from "@/assets/manual/01_selecciona.png";
import manual02 from "@/assets/manual/02_sucursal.png";
import manual03 from "@/assets/manual/03_recoge.png";

// Components
import HelloWorld from "../components/HelloWorld.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      bgImage,
      foto1,
      foto2,
      menuItem,
      manual01,
      manual02,
      manual03,
    };
  },
});
</script>
