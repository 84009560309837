<template>
  <header>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-2">
          <div class="header-style">
            <router-link to="/">
              <img
                class="img-fluid"
                style="max-width:200px;padding: 24px!important;"
                :src="logo"
                alt="Logo Hakuna Bolas de Arroz"
              />
            </router-link>
            <div class="extras bag">
              <v-btn
                to="/"
                icon
                color="secondary"
              >
                <v-icon
                  icon="shopping_bag"
                  color="fff"
                />
              </v-btn>
              <v-btn
                icon
                color="transparent"
                flat
                @click="showMenu = !showMenu"
              >
                <v-icon
                  icon="
                menu"
                  color="fff"
                />
              </v-btn>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <nav class="navbar">
            <ul class="navbar-links">
              <li
                v-for="(mitem,idxm) in links"
                :key="idxm"
                class="navbar-dropdown"
                :class="routeName==mitem.name ?'active':''"
              >
                <router-link :to="mitem.to">{{mitem.text}}</router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-lg-3">
          <div class="extras bag">

            <v-btn
              size="large"
              color="secondary"
            >
              <v-icon
                icon="shopping_bag"
                color="white"
              /> Ordena aquí
            </v-btn>
          </div>
        </div>
        <div
          class="mobile-nav hmburger-menu"
          :class="showMenu ? 'open':''"
          id="mobile-nav"
          style="display:block;"
        >

          <div class="res-log">
            <div style="text-align: right;">
              <v-btn
                size="small"
                variant="outlined"
                icon
                @click="showMenu=false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
            <router-link to="/">
              <img
                class="img-fluid"
                style="padding: 12px!important;"
                :src="logo"
                alt="Logo Hakuna Bolas de Arrozz"
              />
            </router-link>
          </div>
          <ul>
            <li
              v-for="(litem,idxl) in links"
              :key="idxl"
            >
              <router-link :to="litem.to">{{litem.text}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import logo from "@/assets/logo/hakuna-naranja.svg";
export default {
  name: "Header",
  data() {
    return {
      logo,
      routeName: "Inicio",
      showMenu: false,
      links: [
        { to: "/", text: "Inicio", name: "Inicio" },
        { to: "sucursales", text: "Sucursales", name: "Sucursales" },
        { to: "menu", text: "Menú", name: "Menu" },
      ],
    };
  },
  methods: {
    _handleRouteChange() {
      this.routeName = this.$route.name;
    },
  },
  created() {
    this._handleRouteChange();
  },
  watch: {
    $route(a, b) {
      this._handleRouteChange();
    },
  },
};
</script>